<template>
  <div class="home">
    <!-- 首页背景轮播图 -->
    <div class="homeSec01">
      <div class="swiper-container swipertop1">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="(item, index) in homeSec01Img"
            :key="index"
          >
            <div class="homeSec01bg">
              <!-- <img :src="item.img"> -->
              <a :href="item.url" target="_blank">
                <el-image
                  style="width: 100%; height: 100%"
                  :src="item.fileName"
                  fit="cover"
                >
                </el-image>
              </a>
            </div>
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>
    <!-- 新闻轮播+新闻列表 -->
    <div class="homeSec02 wrap clearfloat">
      <div class="homeSec02L">
        <div class="swiper-container swipers2L">
          <div class="swiper-wrapper">
            <!-- <div class="swiper-slide" v-for="(item, index) in homeSec02Img" :key="index">test -->
            <div
              class="swiper-slide"
              v-for="(item, index) in homeSec02Img"
              :key="index"
            >
              <a
                v-if="item.url && item.url !== ''"
                target="_blank"
                :href="item.url"
              >
                <div class="homeSec02Lwrap">
                  <el-image
                    style="width: 100%; height: 100%"
                    :src="item.thumb1"
                    fit="cover"
                  >
                  </el-image>
                  <div class="homeSec02Ldown">
                    <div class="homeSec02LdownTit">{{ item.title }}</div>
                    <div class="homeSec02LdownCont">{{ item.artDesc }}</div>
                    <div class="homeSec02LdownMore">[查看详情]</div>
                  </div>
                </div>
              </a>
              <a
                v-else
                target="_blank"
                :href="`/detail/${item.entTChannelUuid}/${item.entTArticleUuid}`"
              >
                <div class="homeSec02Lwrap">
                  <el-image
                    style="width: 100%; height: 100%"
                    :src="item.thumb1"
                    fit="cover"
                  >
                  </el-image>
                  <!-- <img :src="item.img"> -->
                  <div class="homeSec02Ldown">
                    <div class="homeSec02LdownTit">{{ item.title }}</div>
                    <div class="homeSec02LdownCont">{{ item.artDesc }}</div>
                    <div class="homeSec02LdownMore">[查看详情]</div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div class="swiper-button-prev swiper-button-white swiper_btn"></div>
        <div class="swiper-button-next swiper-button-white swiper_btn"></div>
      </div>
      <div class="homeSec02R">
        <div class="homeSec02Rwrap">
          <div class="homeSec02RMenu clearfloat">
            <div class="menuName">
              <img src="../assets/image/homeSec02RMenu.png" />
              <div v-for="(item, index) in nav1" :key="index">
                <a
                  :href="item.url"
                  target="_blank"
                  :class="{ nav1OnCss: nav1On == index }"
                  @mouseenter="addnav1Css(index)"
                >
                  {{ item.title }}
                </a>
              </div>
            </div>
            <!-- <div class="more">
              <a href="item.url" target="_blank">MORE</a>
            </div> -->
          </div>
          <div class="homeSec02RContent">
            <ul class="menuContent">
              <div v-if="nav1On == 0">
                <li>
                  <div class="more">
                    <a href="/list/02220001" target="_blank">MORE</a>
                  </div>
                  <div
                    class="contentList"
                    v-for="(item, index) in companyNewsList"
                    :key="index"
                  >
                    <a
                      v-if="item.url && item.url !== ''"
                      target="_blank"
                      :href="item.url"
                      class="listTitile"
                      >{{ item.title }}</a
                    >
                    <a
                      v-else
                      target="_blank"
                      :href="`/detail/${item.entTChannelUuid}/${item.entTArticleUuid}`"
                      class="listTitile"
                      >{{ item.title }}</a
                    >
                    <div class="listTime">
                      {{ dayjs(item.publishDate).format("YYYY-MM-DD") }}
                    </div>
                  </div>
                </li>
              </div>
              <div v-if="nav1On == 1">
                <li>
                  <div class="more">
                    <a href="/list/02220002" target="_blank">MORE</a>
                  </div>
                  <div
                    class="contentList"
                    v-for="(item, index) in jtNewsList"
                    :key="index"
                  >
                    <a
                      v-if="item.url && item.url !== ''"
                      target="_blank"
                      :href="item.url"
                      class="listTitile"
                      >{{ item.title }}</a
                    >
                    <a
                      v-else
                      target="_blank"
                      :href="`/detail/${item.entTChannelUuid}/${item.entTArticleUuid}`"
                      class="listTitile"
                      >{{ item.title }}</a
                    >
                    <div class="listTime">
                      {{ dayjs(item.publishDate).format("YYYY-MM-DD") }}
                    </div>
                  </div>
                </li>
              </div>
              <div v-if="nav1On == 2">
                <li>
                  <div class="more">
                    <a href="/list/02220003" target="_blank">MORE</a>
                  </div>
                  <div
                    class="contentList"
                    v-for="(item, index) in grassrootsList"
                    :key="index"
                  >
                    <a
                      v-if="item.url && item.url !== ''"
                      target="_blank"
                      :href="item.url"
                      class="listTitile"
                      >{{ item.title }}</a
                    >
                    <a
                      v-else
                      target="_blank"
                      :href="`/detail/${item.entTChannelUuid}/${item.entTArticleUuid}`"
                      class="listTitile"
                      >{{ item.title }}</a
                    >
                    <div class="listTime">
                      {{ dayjs(item.publishDate).format("YYYY-MM-DD") }}
                    </div>
                  </div>
                </li>
              </div>
              <div v-if="nav1On == 3">
                <li>
                  <div class="more">
                    <a href="/list/02220007" target="_blank">MORE</a>
                  </div>
                  <div
                    class="contentList"
                    v-for="(item, index) in mediumNewsList"
                    :key="index"
                  >
                    <a
                      v-if="item.url && item.url !== ''"
                      target="_blank"
                      :href="item.url"
                      class="listTitile"
                      >{{ item.title }}</a
                    >
                    <a
                      v-else
                      target="_blank"
                      :href="`/detail/${item.entTChannelUuid}/${item.entTArticleUuid}`"
                      class="listTitile"
                      >{{ item.title }}</a
                    >
                    <div class="listTime">
                      {{ dayjs(item.publishDate).format("YYYY-MM-DD") }}
                    </div>
                  </div>
                </li>
              </div>
            </ul>
          </div>
        </div>
      </div>
      <div style="clear: both"></div>
      <!-- 公示公告 -->
      <div class="importNotice">
        <div class="noticeTit">
          <img src="../assets/image/noticeTitlogo.png" />
          <p>公示公告</p>
        </div>
        <div class="noticeLink Gd">
          <div class="swiperNotice swiper-container_2" id="littleLink">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="(item, index) in newsNoticeList"
                :key="index"
              >
                <a
                  :href="
                    item.url && item.url !== ''
                      ? item.url
                      : `/detail/${item.entTChannelUuid}/${item.entTArticleUuid}`
                  "
                  target="_blank"
                >
                  <!-- <a href=""> -->
                  <span>{{ item.title }}&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  <span class="time">{{
                    dayjs(item.publishDate).format("YYYY-MM-DD")
                  }}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- N+1模块 -->
    <div class="homeSec03">
      <div class="homeSec03nav wrap">
        <div class="homeSec03TitBox">
          <div
            class="homeSec03Tit"
            v-for="(item, index) in sec3navtit"
            :key="index"
          >
            <!-- 有url则跳转 -->
            <a
              v-if="item.url && item.url !== ''"
              target="_blank"
              :href="item.url"
              @mouseenter="addnav2Css(index)"
            >
              {{ item.name }}
              <div
                class="nav2OnCss"
                :class="{ nav2OnCsshover: nav2On == index }"
              ></div>
            </a>
            <!-- 没有url/url为空，则跳转本地页面 -->
            <a
              v-else
              :href="
                item.catalog == 1
                  ? `/listPage/${item.entTChannelUuid}`
                  : item.catalog == 2
                  ? `/list/${item.entTChannelUuid}`
                  : `/listPic/${item.entTChannelUuid}`
              "
              target="_blank"
              @mouseenter="addnav2Css(index)"
            >
              {{ item.name }}
              <div
                class="nav2OnCss"
                :class="{ nav2OnCsshover: nav2On == index }"
              ></div>
            </a>
          </div>
        </div>
      </div>
      <transition-group name="el-fade-in-linear">
        <!-- <div class="homeSec03wrap" v-show="nav2On == aindex" v-for="(item, aindex) in sec3navtit" :key="aindex"> -->
        <div
          class="homeSec03wrap"
          v-show="nav2On == aindex"
          v-for="(item, aindex) in homeSec03nav"
          :key="aindex"
        >
          <div class="homeSec03bg">
            <!-- <img class="homeSec03bgbottom" src="../assets/image/homeSec03bg1.jpg" > -->
            <el-image
              style="width: 100%; height: 100%"
              :src="item.img"
              fit="cover"
            >
            </el-image>
            <div class="learnmore">
              <div><a :href="item.url">了解详情</a></div>
            </div>
          </div>
        </div>
      </transition-group>
      <transition-group name="el-fade-in-linear">
        <div
          class="homeSec03cont"
          v-show="nav2On == bindex"
          v-for="(item, bindex) in homeSec03nav"
          :key="bindex"
        >
          <div class="text">{{ item.text }}</div>
        </div>
      </transition-group>
    </div>
    <!-- 四建风采 -->
    <div class="homeSec04">
      <div class="homeSec04Wrap wrap">
        <div class="homeSec04Top" style="position: relative">
          <p>四建风采</p>
          <!-- <img src="../assets/image/mp3.png" style="width: 20px; position: absolute; left: 63%; top: 35px;"> -->
        </div>
        <div class="homeSec04Mid1">
          <div
            class="homeSec04Video"
            v-for="(item, index) in homeSec04Video"
            :key="index"
          >
            <a :href="item.url">
              <div class="imgWrap">
                <!-- <el-image style="width: 100%; height: 100%"
                  :src="item.mp4"
                  fit="cover">
                </el-image> -->
                <video
                  :src="item.mp4"
                  type="video/mp4"
                  controls="controls"
                  loop="-1"
                  muted="muted"
                  :poster="item.img"
                >
                  <!-- :id="'startvideo' + (item.index)" -->
                </video>
              </div>
              <p>{{ item.titile }}</p>
            </a>
          </div>
        </div>
        <div style="clear: both"></div>
        <div class="homeSec04Mid2 wrap">
          <div
            class="homeSec04Mid2Wrap"
            :class="'homeSec04Mid0' + (index + 1)"
            v-for="(item, index) in homeSec04Mid2"
            :key="index"
          >
            <a :href="item.url" ref="init">
              <div class="movediv"></div>
              <div class="homeSec04Mid2Tit">
                <div class="miniImg">
                  <!-- <img :src="item.imgOn">
                  <img :src="item.img"> -->
                </div>
                <p>{{ item.titile }}</p>
              </div>
              <div class="homeSec04Mid2Cont">
                <div>{{ item.cont }}</div>
              </div>
            </a>
          </div>
        </div>
        <div style="clear: both"></div>
      </div>
      <!-- 纪检信息 -->
      <div class="Information">
        <div class="linkWrap wrap">
          <el-row :gutter="20">
            <!-- 左 -->
            <el-col :span="12" :sm="12" :md="12" :xs="24">
              <div class="linkL">
                <div class="linkTit">纪检举报中心</div>
                <div class="linkInner">
                  <img src="../assets/image/Broad.png" class="fl" />
                  <p class="fr" style="margin-top:20px">
                    举报电话：12388<br />
                    网络举报：www.12388.gov.cn
                  </p>
                </div>
              </div>
            </el-col>
            <!-- 中 -->
            <el-col :span="12" :sm="12" :md="12" :xs="24">
              <div class="linkM">
                <div class="linkTit">人才招聘中心</div>
                <div class="linkInner">
                  <!-- <img src="../assets/image/Broad.png" class="fl"> -->
                  <p class="fr">
                    招聘电话：0931-4590241<br />
                    招聘邮箱：gssj_hr@163.com<br />
                    招聘地址：甘肃省兰州市西固区福利东路555号甘肃四建人力资源部
                  </p>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <!-- 友情链接 -->
      <div class="homeSec04Bot">
        <div class="friendLink wrap">
          <span>友情链接&nbsp;</span>
          <select onchange="window.open(this.options[selectedIndex].value)">
            <option value="">=== 内部链接 ===</option>
            <option value="http://www.gsjtw.cc">
              甘肃省建设投资(控股)集团总公司
            </option>
            <option value="http://www.gsyj.com.cn">
              甘肃省第一建设集团有限公司
            </option>
            <option value="http://www.gssanjian.com/www">
              甘肃第三建设集团公司
            </option>
            <option value="http://www.gs5j.com">甘肃第五建设集团公司</option>
            <option value="http://www.gsljw.com">
              甘肃第六建设集团股份有限公司
            </option>
            <option value="http://www.gs7j.com.cn">
              甘肃第七建设集团股份有限公司
            </option>
            <option value="http://www.gs8j.com">
              甘肃第八建设集团股份有限公司
            </option>
            <option value="http://www.gsjky.com.cn">
              甘肃省建筑科学研究院
            </option></select
          >&nbsp;
          <select onchange="window.open(this.options[selectedIndex].value)">
            <option value="">=== 政府机构 ===</option>
            <option value="http://www.gansu.gov.cn">中国•甘肃</option>
            <option value="http://www.sasacgs.gov.cn">
              甘肃省国有资产监督管理委员会
            </option>
            <option value="http://www.gsjs.com.cn">
              甘肃省住房和城乡建设厅
            </option>
            <option value="http://www.rst.gansu.gov.cn">
              甘肃省人力资源和社会保障厅
            </option>
            <option value="http://www.gsaic.gov.cn">
              甘肃省工商行政管理局
            </option>
            <option value="http://www.lz.gansu.gov.cn">中国•兰州</option>
            <option value="http://jsj.lanzhou.gov.cn">兰州市城乡建设局</option>
            <option value="http://xgqv.lanzhou.gov.cn">
              西固区政务信息网
            </option></select
          >&nbsp;
          <select onchange="window.open(this.options[selectedIndex].value)">
            <option value="">=== 行业相关 ===</option>
            <option value="http://www.gsjszj.gov.cn">
              甘肃省建设工程安全质量监督局
            </option>
            <option value="http://61.178.32.165/GSJZJGweb/index.aspx">
              甘肃省建筑市场信息网
            </option>
            <option value="http://www.gsgczj.com.cn">甘肃工程造价信息网</option>
            <option value="http://61.178.32.165/GSJZJGWeb/">
              甘肃省工程建设标准管理办公室
            </option></select
          >&nbsp;
          <select onchange="window.open(this.options[selectedIndex].value)">
            <option value="">=== 协会及媒体 ===</option>
            <option value="http://www.zgjzy.org">中国建筑业协会</option>
            <option value="http://www.cacem.com.cn">中国施工企业协会</option>
            <option value="http://www.gsjzzs.com">甘肃省建筑装饰协会</option>
            <option value="http://www.chinajsb.cn">中国建设报</option>
            <option value="http://www.shigongjishu.cn">施工技术</option>
            <option value="http://www.coneco.com.cn">建筑经济</option></select
          >&nbsp;
          <select onchange="window.open(this.options[selectedIndex].value)">
            <option value="">=== 其他 ===</option>
            <option value="http://www.12306.cn">铁路客户服务中心</option>
            <option value="http://www.gsgajt.gov.cn">
              甘肃省公安厅交警总队
            </option>
            <option value="http://www.10086.cn/gs">中国移动网上营业厅</option>
            <option value="http://gs.189.cn">中国电信网上营业厅</option>
            <option value="http://www.cntv.cn">央视网</option>
            <option value="http://www.ifeng.com">凤凰网</option>
            <option value="http://www.sina.com.cn">新浪</option>
            <option value="http://www.163.com">网易</option>
            <option value="http://www.sohu.com">搜狐</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swiper from "swiper";
import {
  articles,
  picArticlesRecommend,
  getChildChannel,
  getAdvert,
} from "@/api/common";
export default {
  name: "home",
  data() {
    return {
      nav1On: 0,
      nav2On: 0,
      mid2On: 0,
      show: true,
      show1: true,
      show2: true,
      cont: "",
      homeSec01Img: [],
      homeSec02Img: [],
      nav1: [
        {
          title: "集团要闻",
          url: "/list/02220001",
        },
        {
          title: "建投要闻",
          url: "/list/02220002",
        },
        {
          title: "基层动态",
          url: "/list/02220003",
        },
        {
          title: "媒体聚焦",
          url: "/list/02220007",
        },
      ],
      companyNewsList: [],
      jtNewsList: [],
      grassrootsList: [],
      mediumNewsList: [],
      noticeLink: [],
      homeSec03nav: [
        {
          // titile: '建筑安装',
          url: "/listPic/02240001",
          text: "甘肃四建坚持“一元为主、多元发展”理念，在做强做优建安主业的同时，不断优化产业结构，积极推进房地产置业、工业制造、投融资、现代商贸服务、科技研发、设计咨询等板块发展，形成“1+N”全产业链协同发展格局，步入高质量发展的快车道。",
          img: require("../assets/image/homeSec03bg1.jpg"),
        },
        {
          // titile: '置业开发',
          url: "/listPic/02240002",
          text: "为适应经济发展的需要，积极探索房地产发展的新思路，拓宽经营领域，秉承对内对外开发相结合的经营理念，坚持“以人为本”的设计思想，为住户创造一个优美、安全、舒适、方便、文明和具有较高文化品味的生活环境。",
          img: require("../assets/image/homeSec03bg2.jpg"),
        },
        {
          // titile: '工业制造',
          url: "/listPic/02240003",
          text: "集科技研发、制造、运输、安装为一体，建筑钢结构施工能力不断提升，拥有铝合金模板、钢制防护网、脚手架等产品，为建筑施工企业提供完整的施工方案。",
          img: require("../assets/image/homeSec03bg3.jpg"),
        },
        {
          // titile: '装饰装修',
          url: "/listPic/02240004",
          text: "贴近生活，触摸未来，打造地标性建筑装饰工程。",
          img: require("../assets/image/homeSec03bg4.jpg"),
        },
        {
          // titile: '设计咨询',
          url: "/listPic/02240005",
          text: "积极主动适应设计建筑产业一体化发展趋势，开拓发展设计咨询新业态。",
          img: require("../assets/image/homeSec03bg5.jpg"),
        },
        {
          // titile: '投融资',
          url: "/listPic/02240006",
          text: "以投融资建设运营全新模式助力城市发展。",
          img: require("../assets/image/homeSec03bg6.jpg"),
        },
        {
          // titile: '钢结构建造',
          url: "/listPic/02240008",
          text: "擎起城市脊梁，着力解打造钢结构行业标杆。",
          img: require("../assets/image/homeSec03bg7.jpg"),
        },
        {
          // titile: '装配式工程',
          url: "/listPic/02240009",
          text: "智能制造，打造装配式建筑新高地。",
          img: require("../assets/image/homeSec03bg8.jpg"),
        },
        {
          // titile: '现代服务业',
          url: "/listPic/02240010",
          text: "致力于工程项目的大宗材料采购、供应及管理。",
          img: require("../assets/image/homeSec03bg9.jpg"),
        },
        {
          // titile: '工程检试验',
          url: "/listPic/02240011",
          text: "以一流的检测队伍，一流的检测方法，一流的工作效率，做到检测数据公正、准确、科学，为各项试验检测任务提供优质服务。",
          img: require("../assets/image/homeSec03bg10.jpg"),
        },
        {
          // titile: '省外项目',
          url: "/listPic/02240007",
          text: "坚定不移实施“走出去”发展战略，持续扩大合作“朋友圈”，省外市场稳步扩大，切实实现从“走进去”到“融进去”再到“落下来”的属地化发展目标。",
          img: require("../assets/image/homeSec03bg11.jpg"),
        },
      ],
      sec3navtit: [],
      homeSec04Video: [
        {
          titile: "甘肃四建集团宣传片",
          mp4: require("../assets/video/vxcp.mp4"),
          // mp4: "http://img.gsjtzn.cn/newimages/Upload/video/2023/01/8da81dc55cc44c5bafaa64dcc6133add.mp4",
          img: require("../assets/image/vxcp.png"),
          url: "/list/022500030001",
        },
        {
          titile: "70周年专题片",
          mp4: require("../assets/video/v70ztp.mp4"),
          img: require("../assets/image/70ztp.png"),
          url: "/list/022500030002",
        },
        {
          titile: "走进初心园",
          mp4: require("../assets/video/vcxy.mp4"),
          img: require("../assets/image/vcxy.png"),
          url: "/list/022500030003",
        },
      ],
      homeSec04Mid2: [
        {
          titile: "党建引领",
          cont: "坚持党的领导，加强党的建设是国有企业的“根”和“魂”。",
          img: require("../assets/image/homeSec04Mid1.png"),
          imgOn: require("../assets/image/homeSec04Mid11.png"),
          url: "/list/02230001",
        },
        {
          titile: "创新赋能",
          cont: "依托“国家级高新技术企业”和“省级技术中心”的平台优势建设科技创新服务体系。",
          img: require("../assets/image/homeSec04Mid2.png"),
          imgOn: require("../assets/image/homeSec04Mid22.png"),
          url: "/list/02260003",
        },
        {
          titile: "文化强企",
          cont: "文化兴则企业兴，甘肃四建聚焦“打造现代一流企业”宏伟目标，持续开展文化体系梳理，夯实文化制度建设。",
          img: require("../assets/image/homeSec04Mid3.png"),
          imgOn: require("../assets/image/homeSec04Mid33.png"),
          url: "/list/02250001",
        },
        {
          titile: "品质匠心",
          cont: "建无止境，臻致以新。",
          img: require("../assets/image/homeSec04Mid4.png"),
          imgOn: require("../assets/image/homeSec04Mid44.png"),
          url: "/listPic/02260002",
        },
      ],
      newsNoticeList: [],
    };
  },
  methods: {
    addnav1Css(index) {
      // console.log(666,index);
      this.nav1On = index;
    },
    addnav2Css(index) {
      this.nav2On = index;
    },
    addmid2Css(index) {
      // console.log(13,index);
      this.mid2On = index;
    },
    swiperLink() {
      var _this = this;
      let data = {
        entTApplicationUuid: _this.webId,
        channelUuid: "02270002",
        pageNum: 1,
        pageSize: 8,
      };
      articles(data).then((res) => {
        const { code, data } = res;
        if (code === 1 && data) {
          _this.newsNoticeList = data.rows;
          if (_this.newsNoticeList.length) {
            setTimeout(() => {
              this.swiper = new Swiper(
                "#littleLink",
                {
                  autoplay: 1,
                  loop: true,
                  speed: 10000,
                  delay: 0,
                  slidesPerView: "auto",
                  spaceBetween: 45,
                  observer: true,
                  observeParents: true,
                  observeSlideChildren: true,
                  autoplayDisableOnInteraction: false,
                },
                500
              );
              // 存放鼠标悬浮时的transform属性（行内属性）
              let nextTransForm = "";
              // 轮播图从暂停位置移动到原本应到的位置所用的时间
              let nextTime = 0;
              // 鼠标悬浮暂停轮播
              // setTimeout(() => {
              this.swiper.container[0].onmouseover = function () {
                nextTransForm = document
                  .getElementsByClassName("swiper-container_2")[0]
                  .getElementsByClassName("swiper-wrapper")[0].style.transform;
                // 轮播图原本应移动到的位置
                let nextTransPosition =
                  -1 *
                  parseInt(
                    document
                      .getElementsByClassName("swiper-container_2")[0]
                      .getElementsByClassName("swiper-wrapper")[0]
                      .style.transform.split("translate3d(")[1]
                      .split("px")[0]
                  );
                // 鼠标悬浮时时轮播图位置
                let nowTransPosition =
                  -1 *
                  parseInt(
                    window
                      .getComputedStyle(
                        document
                          .getElementsByClassName("swiper-container_2")[0]
                          .getElementsByClassName("swiper-wrapper")[0],
                        false
                      )["transform"].split("1, ")[2].split(",")[0]
                  );
                // 存放鼠标悬浮时轮播图的真实transform属性（非行内属性）
                let nowTransForm = window.getComputedStyle(
                  document
                    .getElementsByClassName("swiper-container_2")[0]
                    .getElementsByClassName("swiper-wrapper")[0],
                  false
                )["transform"];
                // 计算轮播图从暂停位置移动到原本应到的位置所用的时间（370是我自定义的每个slide的宽度）
                nextTime =
                  5500 * ((nextTransPosition - nowTransPosition) / 370);
                // 改变行内transform属性
                document
                  .getElementsByClassName("swiper-container_2")[0]
                  .getElementsByClassName("swiper-wrapper")[0].style.transform =
                  nowTransForm;
                // 不写也没关系
                document
                  .getElementsByClassName("swiper-container_2")[0]
                  .getElementsByClassName(
                    "swiper-wrapper"
                  )[0].style.transitionDuration = "0ms";
                this.swiper.stopAutoplay();
              };
              // 鼠标离开轮播图开始轮播
              this.swiper.container[0].onmouseout = function () {
                // console.log('leave' + nextTransForm)
                // 恢复原样
                document
                  .getElementsByClassName("swiper-container_2")[0]
                  .getElementsByClassName("swiper-wrapper")[0].style.transform =
                  nextTransForm;
                document
                  .getElementsByClassName("swiper-container_2")[0]
                  .getElementsByClassName(
                    "swiper-wrapper"
                  )[0].style.transitionDuration = nextTime + "ms";
                this.swiper.startAutoplay();
              };
              // },2500)
            });
          }
        }
      });
    },
    // banner首页头部轮播图
    swiperTop() {
      var _this = this;
      let data = {
        applicationUuid: _this.webId,
        advertType: 1,
      };
      getAdvert(data).then((res) => {
        const { code, data } = res;
        if (code === 1 && data) {
          data.length &&
            data.forEach((line) => {
              if (line.fileName && line.fileName !== "") {
                _this.homeSec01Img.push(line);
              }
            });
          setTimeout(() => {
            new Swiper(".swipertop1", {
              loop: true,
              effect: "fade",
              autoplay: 3000,
              // speed: 1000,
              pagination: ".swiper-pagination",
              paginationClickable: true,
              observer: true,
              observeParents: true,
              observeSlideChildren: true,
              autoplayDisableOnInteraction: false,
            });
          }, 500);
        }
      });
    },
    // 集团要闻
    companyNews() {
      var _this = this;
      let data = {
        channelUuid: "02220001",
        entTApplicationUuid: _this.webId,
        pageNum: 1,
        pageSize: 7,
      };
      articles(data).then((res) => {
        // console.log(1111, res)
        const { code, data } = res;
        if (code === 1 && data) {
          _this.companyNewsList = data.rows;
          // console.log(1111, _this.companyNewsList)
        }
      });
    },
    // 建投要闻
    jtNews() {
      var _this = this;
      let data1 = {
        channelUuid: "00190001",
        entTApplicationUuid: _this.webIdGroup,
        pageNum: 1,
        pageSize: 7,
      };
      articles(data1).then((res) => {
        // console.log(1111, res)
        const { code, data } = res;
        // code = 1传值正确 && data有数据
        if (code === 1 && data) {
          _this.jtNewsList = data.rows;
          // console.log(1222, _this.jtNewsList)
        }
      });
    },
    // 基层动态
    grassroots() {
      var _this = this;
      let data2 = {
        channelUuid: "02220003",
        entTApplicationUuid: _this.webId,
        pageNum: 1,
        pageSize: 7,
      };
      articles(data2).then((res) => {
        // console.log(3333, res)
        const { code, data } = res;
        // code = 1传值正确 && data有数据
        if (code === 1 && data) {
          _this.grassrootsList = data.rows;
          // console.log(3333, _this.grassrootsList)
        }
      });
    },
    // 媒体聚焦
    mediumNews() {
      var _this = this;
      let data3 = {
        channelUuid: "02220007",
        entTApplicationUuid: _this.webId,
        pageNum: 1,
        pageSize: 7,
      };
      articles(data3).then((res) => {
        // console.log(444, res)
        const { code, data } = res;
        // code = 1传值正确 && data有数据
        if (code === 1 && data) {
          _this.mediumNewsList = data.rows;
          // console.log(444, _this.mediumNewsList)
        }
      });
    },
    sec3nav() {
      var _this = this;
      let data = {
        applicationUuid: _this.webId,
        parentUuid: "0224",
        // parentUuid: "0"
      };
      getChildChannel(data).then((res) => {
        // console.log(444, res)
        const { code, data } = res;
        // code = 1传值正确 && data有数据
        if (code === 1 && data) {
          _this.sec3navtit = data;
          // console.log(444, _this.sec3navtit)
        }
      });
    },

    sec02L() {
      var _this = this;
      let data = {
        // channelUuid: "02220001",
        entTChannelUuid: "02220001",
        entTApplicationUuid: _this.webId,
        pageNum: 1,
        pageSize: 4,
      };
      picArticlesRecommend(data).then((res) => {
        const { code, data } = res;
        // code = 1传值正确 && data有数据
        if (code === 1 && data) {
          _this.homeSec02Img = data.rows;
          setTimeout(() => {
            new Swiper(".swipers2L", {
              loop: true,
              loopAdditionalSlides: 1,
              // autoplay: true,
              autoplay: 3000,
              speed: 1000,
              observer: true,
              observeParents: true,
              observeSlideChildren: true,
              autoplayDisableOnInteraction: false,
              prevButton: ".swiper-button-prev",
              nextButton: ".swiper-button-next",
            });
          }, 500);
        }
      });
    },
    // 视频
    // mid1video() {
    //     for (var i = 0; i < 4; i++) {
    //     //获取四个视频的id
    //         // var video = document.getElementById("'startvideo' + (item.index)");
    //     //调用video标签的播放函数
    //         // video.play();
    //     }
    // }
  },
  mounted() {
    setTimeout(() => {
      this.swiperTop();
    }, 500);
    // test!!!
    setTimeout(() => {
      new Swiper(".swipers2L", {
        loop: true,
        loopAdditionalSlides: 1,
        // autoplay: true,
        autoplay: 3000,
        speed: 1000,
        observer: true,
        observeParents: true,
        observeSlideChildren: true,
        autoplayDisableOnInteraction: false,
        prevButton: ".swiper-button-prev",
        nextButton: ".swiper-button-next",
      });
    }, 500);
    this.swiperLink();
    this.companyNews();
    this.jtNews();
    this.grassroots();
    this.mediumNews();
    this.sec02L();
    // this.swipers2L()
    this.sec3nav();
    // this.mid1video()
  },
  components: {
    // Swiper,
    // SwiperSlide,
  },
  filters: {
    //超过100位显示 ...
    // ellipsis: function(value) {
    //   if (!value) return ''
    //   if (value.length > 54) {
    //     return value.slice(0, 54) + '...'
    //   }
    //   return value
    // },
  },
};
</script>
<style>
.wrap {
  width: 1300px;
  margin: 0 auto;
}
/* .containerMain {
  position: relative;
  top: -60px;
} */
.homeSec01 {
  width: 100%;
  height: calc(100vh - 60px);
  position: relative;
}
.homeSec01 .swiper-container,
.homeSec01 .swipertop1 .swiper-wrapper,
.homeSec01 .swipertop1 .swiper-slide {
  width: 100%;
  height: 100%;
}
/* 首页轮播图切换按钮 */
.swipertop1 .swiper-pagination-bullet-active,
.swiper-pagination-bullet {
  width: 80px;
  height: 5px;
  border-radius: 2px;
  background-color: #000000;
  opacity: 0.18;
  position: relative;
  top: 0;
}
.swipertop1 .swiper-pagination-bullet-active {
  background-color: #ffffff;
  opacity: 0.7;
}
.homeSec01bg {
  /* margin-top: -360px; */
  width: 100%;
  height: 100%;
  position: absolute;
}
.homeSec02 {
  /* margin-top: 10px; */
  height: auto;
}
.homeSec02L {
  width: 45%;
  height: 460px;
  /* background-color: pink; */
  float: left;
  position: relative;
}
.homeSec02Lwrap {
  width: 100%;
  height: 87%;
  position: absolute;
  margin-top: 30px;
}
.swipers2L {
  width: 100%;
  height: 100%;
  /* background-color: #EAA334; */
}
.homeSec02Lwrap img {
  width: 100%;
  height: 100%;
  transition: all 0.5s linear;
}
.swipers2L:hover .homeSec002Lwrap img {
  transform: scale(1.1);
}
.homeSec02Ldown {
  width: 100%;
  height: 16%;
  bottom: 0;
  position: absolute;
  background-color: rgb(0, 0, 0);
  overflow: hidden;
  opacity: 0.7;
}
.homeSec02LdownTit {
  width: 80%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: auto;
  height: 30px;
  line-height: 30px;
  /* background-color: rgb(255, 255, 0); */
  text-align: center;
  color: white;
}
.homeSec02LdownCont {
  float: left;
  width: 80%;
  height: 30px;
  line-height: 25px;
  color: white;
  font-size: 13px;
  padding-left: 8px;
  /* background-color: rgb(0, 255, 0); */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.homeSec02LdownMore {
  float: right;
  width: 15%;
  line-height: 25px;
  color: #0170c1;
}
.homeSec02L .swipers2L .swiper_btn {
  width: 30px;
  height: 25px;
}
.homeSec02R {
  /* width: 680px; */
  width: 52%;
  height: 460px;
  /* background-color: rgb(189, 182, 250); */
  float: right;
}
.homeSec02Rwrap {
  width: 100%;
  height: 430px;
  /* background-color: rgb(168, 159, 248); */
  margin-top: 15px;
  overflow: hidden;
}
.homeSec02RMenu {
  height: 45px;
  border-bottom: 1px solid #767676;
  overflow: hidden;
  position: relative;
}
.homeSec02RMenu .menuName img {
  width: 35px;
  height: 35px;
  float: left;
  margin-top: 5px;
}
.homeSec02RMenu .menuName a {
  text-decoration: none;
  float: left;
  width: 16%;
  text-align: center;
  line-height: 35px;
  padding-top: 8px;
  color: black;
  font-size: 18px;
  font-weight: bold;
  transition: all 0.3s ease-out;
}
/* .homeSec02RMenu .more a{
  text-decoration: none; */
/* float:right; */
/* color: #767676;
  font-size: 18px;
  padding-top: 13px;
  background-color: #EAA334;
  right: 0;
  position: absolute;
  transition: all 0.5s ease-out;
} */
/* .homeSec02RMenu .more a:hover{
  color: #0170C1;
} */
/* 悬停菜单样式 */
.homeSec02RMenu .menuName .nav1OnCss {
  color: #0170c1;
  border-bottom: 2px solid #0170c1;
  font-weight: bold;
}
.homeSec02RContent {
  margin-top: 30px;
}
.contentList .listTitile {
  float: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 75%;
}
.menuContent .contentList .listTitile:hover {
  color: #0170c1;
}
.contentList .listTime {
  float: right;
  font-weight: 600;
  font-size: 17px;
  line-height: 50px;
}
.contentList a {
  font-size: 17px;
  line-height: 50px;
}
.menuContent {
  position: relative;
}
.menuContent li .more a {
  text-decoration: none;
  color: #767676;
  font-size: 18px;
  /* background-color: #34fa4b; */
  right: 0;
  margin-top: -9%;
  position: absolute;
  transition: all 0.5s ease-out;
}
.menuContent li .more a:hover {
  color: #0170c1;
}
/* 公示公告 */
.importNotice {
  height: 40px;
  width: 100%;
  background-color: #f1f1f1;
  margin-top: 20px;
  /* position: relative; */
}
.noticeTit {
  height: 100%;
  width: 13%;
  /* background-color: yellow; */
  line-height: 40px;
  color: #ff0000;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  float: left;
}
.noticeTit img {
  width: 25px;
  height: 25px;
  margin: 8px 10px 0 10px;
  float: left;
}
.noticeTit p {
  float: left;
}
.noticeLink {
  height: 100%;
  width: 86%;
  float: left;
  line-height: 40px;
  /* background-color: #EAA334; */
  overflow: hidden;
}
.noticeLink a:hover {
  color: #337ab7;
}
#littleLink .swiper-wrapper {
  transition-timing-function: linear;
}
#littleLink .swiper-slide {
  width: auto;
}
.homeSec03 {
  height: 760px;
  margin-top: 20px;
  /* overflow: hidden; */
  position: relative;
}
.homeSec03nav {
  width: 1300px;
  height: 100%;
  z-index: 30;
  position: relative;
}
.homeSec03TitBox {
  position: absolute;
  top: 50%;
  width: 100px;
  transform: translate(60%, -50%);
  /* background-color: rgb(0, 98, 177, 0.5); */
  /* background-color: rgba(255, 255, 255, 0.7);
  border-radius: 10px;
  padding-bottom: 32px; */
}
.homeSec03Tit {
  text-align: center;
  /* width: 80%; */
  position: relative;
}
.homeSec03Tit a {
  display: block;
  font-size: 16px;
  font-weight: bold;
  color: white;
  /* color: #0170C1; */
  line-height: 25px;
  border-bottom: 2px solid transparent;
  margin-top: 32px;
  transition: all 0.8s ease-out;
}
.nav2OnCss {
  width: 0%;
  height: 2px;
  text-align: center;
  left: 50%;
  background-color: white;
  position: absolute;
  bottom: 0;
  transition: all 0.3s ease-in;
}
.nav2OnCsshover {
  width: 100%;
  left: 0%;
}
/* .homeSec03Tit:hover .nav2OnCss{
  width: 100%;
  left: 0%;
} */
/* .homeSec03Tit:after{
  content: '';
  width: 0;
  height: 2px;
  position: absolute;
  top: 100%;
  left: 50%;
  background-color: white;
  transition: all 0.5s ease-out;
}
.homeSec03Tit:hover:after{
  left: 0%;
  width: 100%;
} */
.homeSec03wrap {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
}
.homeSec03bg {
  height: 100%;
}
.homeSec03bgbottom {
  position: absolute;
}
.homeSec03bg img {
  width: 100%;
  z-index: -30;
  height: 100%;
}
.homeSec03cont {
  background-color: rgba(1, 112, 193, 0.7);
  border-top-left-radius: 65px;
  right: 0;
  bottom: 0;
  width: 800px;
  height: 170px;
  color: #ffffff;
  margin: -360px 0 0 600px;
  font-size: 14px;
  position: absolute;
  /* transition: all 0.5s linear; */
}
.homeSec03cont .text {
  text-align: left;
  width: 720px;
  height: 84px;
  line-height: 28px;
  margin: 30px 0 0 50px;
  text-indent: 2em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.learnmore:hover a {
  color: #0170c1;
}
.learnmore {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 50;
  margin: 0 690px 20px 0;
}
.learnmore a {
  background-color: #fff;
  padding: 3px 10px;
  transition: all 0.5s linear;
}
.homeSec04 {
  background-color: #efeff1;
  margin: 0 auto;
  height: auto;
}
.homeSec04Wrap {
  width: 1300px;
  /* width: 100%; */
  height: auto;
  margin: 0 auto;
}
.homeSec04Top {
  /* background-color: #abcea7; */
  margin: 0 auto;
  width: 60%;
  height: 90px;
}
.homeSec04Top p {
  /* background-color: #abcea7; */
  margin: 0 auto;
  color: #0170c1;
  text-align: center;
  width: 100%;
  height: 100%;
  line-height: 90px;
  letter-spacing: 22px;
  font-size: 22px;
  font-weight: bold;
}
.homeSec04Mid1 {
  /* background-color: #849e81; */
  width: 80%;
  height: 237px;
  margin: 10px auto 20px;
}
.homeSec04Video {
  width: 30%;
  height: 100%;
  float: left;
  margin: 0 13px;
  transition: all 0.5s linear;
  top: 0;
  box-shadow: 5px 5px 10px #b0b0b0;
  position: relative;
}
.homeSec04Video a {
  width: 100%;
  height: 100%;
}
.homeSec04Video .imgWrap {
  width: 100%;
  height: 82%;
}
.homeSec04Video video {
  width: 100%;
  height: 100%;
  transition: 0.5s all ease-in;
}
video {
  object-fit: cover;
}
.homeSec04Video p {
  background-color: #ffffff;
  width: 100%;
  height: 18%;
  line-height: 45px;
  text-indent: 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.homeSec04Video:hover {
  box-shadow: 5px 5px 15px #5a5a5a;
  top: -8px;
}
.homeSec04Video:hover .imgWrap img {
  transform: scale(1.1);
}
.homeSec04Mid2 {
  /* background-color: #5d715b; */
  width: 100%;
  height: 230px;
  margin: 20px auto;
}
.homeSec04Mid2Wrap {
  float: left;
  width: 24%;
  height: 220px;
  margin: auto 0.5%;
  position: relative;
  overflow: hidden;
}
.homeSec04Mid2Wrap a {
  width: 100%;
  height: 220px;
  display: block;
  transition: all 0.5s ease-in;
  background-color: #ffffff;
}
.movediv {
  /* width: 30px; */
  width: 100%;
  height: 6px;
  border: 0px solid #eaa334;
  position: absolute;
  bottom: 0;
  transition: all 0.5s ease-in;
}
.homeSec04Mid2Wrap:hover .movediv {
  border-left-width: 312px;
}
.homeSec04Mid2Tit {
  width: 40%;
  /* background-color: #dd7979;      */
  color: #0170c1;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  padding: 45px 0 5px 20px;
  transition: all 0.8s ease-out;
  position: relative;
  top: 0;
  /* margin-bottom: 5px; */
}
.miniImg {
  width: 30px;
  height: 30px;
  margin: 0 auto 10px;
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
  transition: all 0.8s ease-out;
}
.homeSec04Mid01 .miniImg {
  background-image: url(../assets/image/homeSec04Mid1.png);
}
.homeSec04Mid01:hover .miniImg {
  background-image: url(../assets/image/homeSec04Mid11.png);
}
.homeSec04Mid02 .miniImg {
  background-image: url(../assets/image/homeSec04Mid2.png);
}
.homeSec04Mid02:hover .miniImg {
  background-image: url(../assets/image/homeSec04Mid22.png);
}
.homeSec04Mid03 .miniImg {
  background-image: url(../assets/image/homeSec04Mid3.png);
}
.homeSec04Mid03:hover .miniImg {
  background-image: url(../assets/image/homeSec04Mid33.png);
}
.homeSec04Mid04 .miniImg {
  background-image: url(../assets/image/homeSec04Mid4.png);
}
.homeSec04Mid04:hover .miniImg {
  background-image: url(../assets/image/homeSec04Mid44.png);
}
.homeSec04Mid2Cont {
  width: 90%;
  height: 56px;
  line-height: 24px;
  padding: 0 20px 20px 20px;
  color: #696969;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  transition: all 0.8s ease-out;
}
/* 悬停 */
.homeSec04Mid2Wrap a:hover {
  background-color: #0062b1;
}
.homeSec04Mid2Wrap:hover .homeSec04Mid2Tit {
  top: -25px;
  color: #fff;
}
.homeSec04Mid2Wrap:hover .homeSec04Mid2Cont {
  opacity: 0%;
}
.homeSec04Bot {
  /* background-color: #c3d3c1; */
  width: 100%;
  height: 70px;
  /* padding-bottom: 20px; */
}
.friendLink {
  border: 1px solid darkgray;
  background-color: white;
  text-align: center;
  padding: 10px 0;
  box-shadow: 1px 1px 2px #000;
}
.friendLink span {
  color: #0055aa;
}
select {
  font-family: inherit;
  width: 17.5%;
  /* text-align: center; */
  background: url("../assets/image/arrow.png") right 8px center no-repeat;
}
option {
  text-align: center;
}
/* 纪检信息 */
.Information {
  height: auto;
  width: 100%;
  margin: 0px auto 30px;
  /* background: url(../assets/image/Links.jpg); */
  background-repeat: no-repeat;
  overflow: hidden;
  background-size: 100% 100%;
}
.linkWrap {
  height: auto;
}
.linkL,
.linkM {
  background-color: rgb(255, 255, 255);
  height: 180px;
  margin: 20px 5px 0;
  padding-top: 20px;
  /* margin-top: 30px; */
}
.linkL .linkInner img {
  width: 13%;
}
.linkTit {
  text-align: center;
  font-size: 22px;
  height: 30%;
  line-height: 54px;
  letter-spacing: 5px;
  font-weight: bold;
  color: rgb(2, 99, 180);
  /* background-color: red; */
}
.linkInner {
  /* background-color: rgba(255, 0, 0, 0.5); */
  width: 100%;
  height: 70%;
}
.linkInner img {
  height: 100%;
  width: 18%;
  /* background-color: rgba(0, 255, 145, 0.5); */
}
.linkInner p {
  /* height: 100%; */
  width: 80%;
  /* background-color: rgba(255, 0, 251, 0.5); */
  color: rgb(2, 99, 180);
  font-size: 15px;
  line-height: 30px;
}
.linkM .linkInner p {
  /* height: 100%; */
  width: 86%;
}
@media (max-width: 1366px) {
  .containerMain {
    top: 0px;
  }
  .homeSec01 {
    height: 768px;
  }
  .homeSec02L {
    height: 420px;
  }
  .homeSec02RMenu .menuName a {
    width: 18%;
  }
  .homeSec02RContent {
    margin-top: 15px;
  }
  .contentList .listTitile {
    width: 75%;
  }
  .homeSec02 .importNotice {
    width: 100%;
    margin: auto;
  }
  .homeSec04 {
    height: auto;
  }
  .homeSec04Wrap {
    width: 1000px;
  }
  .contentList a {
    line-height: 48px;
  }
  .contentList .listTime {
    line-height: 48px;
  }
  .menuContent li .more a {
    margin-top: -48px;
  }
  .homeSec04Mid1 {
    height: 200px;
  }
  .homeSec04Mid2 {
    height: 200px;
  }
  .homeSec04Mid2Wrap a {
    height: 180px;
  }
  .homeSec04Mid2Tit {
    padding: 45px 0 0 20px;
  }
  .homeSec04Mid2Wrap:hover .movediv {
    border-left-width: 230px;
  }
  .homeSec04Mid2Wrap .movediv {
    margin-bottom: 30px;
  }
  .linkWrap .el-col {
    padding: 0;
  }
}
@media (max-width: 992px) {
  .homeSec01 {
    height: 500px;
  }
  .homeSec02 {
    width: 95%;
  }
  .homeSec02L {
    width: 45%;
    height: 350px;
  }
  .homeSec02R {
    height: 350px;
  }
  .homeSec02RMenu .menuName a {
    width: 19%;
  }
  .homeSec02RContent {
    margin-top: 10px;
  }
  .contentList .listTitile {
    width: 75%;
  }
  .contentList a {
    line-height: 40px;
  }
  .contentList .listTime {
    line-height: 40px;
  }
  .menuContent li .more a {
    margin-top: -42px;
  }
  .homeSec02 .importNotice {
    width: 100%;
    margin: 20px auto 0;
  }
  .homeSec03Tit a {
    margin-top: 30px;
  }
  .homeSec03TitBox {
    transform: translate(100%, -63%);
  }
  .homeSec04 {
    width: 100%;
  }
  .homeSec04Wrap {
    width: 100%;
  }
  .homeSec04Mid1 {
    height: 190px;
  }
  .homeSec04Video {
    margin: 0 11px;
  }
  .homeSec04Mid2 {
    height: 200px;
    width: 95%;
  }
  .homeSec04Mid2Wrap:hover .movediv {
    border-left-width: 0px;
  }
  .homeSec04Bot {
    width: 95%;
  }
  .linkWrap .el-col {
    padding: 0;
  }
}
@media (max-width: 850px) {
  .homeSec01 {
    height: 400px;
  }
  .homeSec02 {
    width: 95%;
  }
  .homeSec02L {
    width: 43%;
    height: 350px;
  }
  .homeSec02R {
    width: 56%;
    height: 350px;
  }
  .homeSec02RMenu .menuName a {
    width: 19%;
  }
  .homeSec02RContent {
    margin-top: 10px;
  }
  .contentList .listTitile {
    width: 75%;
  }
  .contentList a {
    line-height: 40px;
  }
  .contentList .listTime {
    line-height: 40px;
  }
  .menuContent li .more a {
    margin-top: -43px;
  }
  .homeSec02 .importNotice {
    width: 100%;
    margin: 20px auto 0;
  }
  .noticeTit {
    width: 20%;
  }
  .noticeLink {
    width: 77%;
  }
  .homeSec03 {
    height: 460px;
  }
  .homeSec03cont {
    width: 650px;
  }
  .homeSec03cont .text {
    width: 570px;
    line-height: 28px;
  }
  .homeSec03Tit a {
    margin-top: 15px;
  }
  .homeSec03TitBox {
    transform: translate(60%, -50%);
  }
  .learnmore {
    right: 0;
    bottom: 0;
    margin: 0 540px 20px 0;
  }
  .homeSec04 {
    width: 100%;
  }
  .homeSec04Wrap {
    width: 100%;
  }
  .homeSec04Mid1 {
    height: 190px;
  }
  .homeSec04Video {
    margin: 0 10px;
  }
  .homeSec04Mid2 {
    height: 200px;
    width: 95%;
  }
  .homeSec04Bot {
    width: 95%;
  }
  .linkWrap .el-col {
    padding: 0;
  }
}
@media (max-width: 768px) {
  .homeSec01 {
    height: 300px;
  }
  .homeSec02L {
    width: 95%;
    height: 350px;
    float: none;
    margin: auto;
  }
  .homeSec02R {
    width: 95%;
    height: auto;
    float: none;
    margin: auto;
  }
  .homeSec02Rwrap {
    height: auto;
  }
  .homeSec02RMenu .menuName a {
    width: 20%;
    font-size: 17px;
  }
  .homeSec02RMenu .menuName img {
    display: none;
  }
  .contentList a {
    line-height: 35px;
  }
  .contentList .listTime {
    line-height: 35px;
  }
  .menuContent li .more a {
    margin-top: -40px;
  }
  .homeSec02 .importNotice {
    width: 95%;
  }
  .noticeTit p {
    display: none;
  }
  .noticeTit {
    width: 11%;
  }
  .noticeLink {
    width: 85%;
  }
  .homeSec03 {
    height: 360px;
  }
  .homeSec03Tit a {
    margin-top: 10px;
  }
  .homeSec03TitBox {
    transform: translate(80%, -52%);
  }
  .homeSec03cont {
    display: none;
  }
  .homeSec03bg .learnmore {
    display: none;
  }
  /* .homeSec04{
    height: auto;
  } */
  .homeSec04Wrap {
    width: 100%;
  }
  .homeSec04Mid1 {
    width: 95%;
    height: 150px;
  }
  .homeSec04Video {
    height: auto;
    margin: 0 5px;
  }
  .homeSec04Video .imgWrap {
    height: 100px;
  }
  .homeSec04Mid2 {
    /* height: 200px; */
    width: 98%;
  }
  .homeSec04Mid2Wrap {
    width: 45%;
    margin: auto 2%;
  }
  .homeSec04Bot {
    width: 95%;
    height: auto;
  }
  .friendLink span {
    display: none;
  }
  select {
    width: 80%;
    margin: 3px 0;
  }
}
</style>
